
import { Frame } from '../framework.js'

import { fieldErrMsg, debounce, exclusive, thisRemoteError, NavFrame, showModal } from '../common.js'

import { frmTicket } from './ticket.js'

export async function frmTickets(api, ctx) {

  var tickets = await api.getTicketsApi()

  var frameOpts = {
    ...ctx,
    template: 'tickets',
    label: 'Tickets',
  }  
  var frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-tech`)

  async function ticket_load(ticket) {
    var entry = await ticket.load()
    const ticketCtx = {
      node: ctx.node,
      menu: ctx.menu,
      nav: frame.subNav,
      label: `${entry.type} ${entry.number}`,
    }
    frmTicket(api, ticket, ticketCtx)
  }

  frame.tables.tickets.onClick(async (entry) => {
    var opened = $('li', frame.subNav).filter((idx, e) => {
      return $(e).attr('label') == `${entry.type} ${entry.number}`
    })
    if(opened.length) {
      opened.find('a').click()
    } else {
      await ticket_load(await tickets.ticket(entry._id))
    }
  })

  frame.$('a.newTicket').on('click', showModal({
    message: 'Êtes-vous sûr de vouloir créer un nouveau ticket?',
    btnOk: 'Créer',
    onOk: async () => {
      await ticket_load(await tickets.newTicket())
    },
  }))

  async function tickets_list() {
    var term = frame.$('input.term').val() || ''
    var statut = frame.$('select.statut').val() || ''
    var sortcol = frame.tables.tickets.$('th[sort][sort!=""]')
    var sortfield = sortcol.attr('field')
    var sortorder = (sortcol.attr('sort') == "down") ? 1 : -1
    var sort = { [sortfield]: sortorder }
    frame.tables.tickets.update(await tickets.list(term, statut, sort))
  }

  frame.$('input.term').on('keyup', debounce(tickets_list, 200))
  frame.$('select.statut').on('change', tickets_list)

  frame.tables.tickets.$('th.sort a').on('click', function () {
    var col = $(this).parent()
    var field = col.attr('field')
    var sort = col.attr('sort')
    col.siblings('.sort').attr('sort', '').find('svg').hide()
    sort = (!sort || sort == 'up') ? 'down' : 'up'
    col.attr('sort', sort)
    col.find('svg').hide()
    col.find(`svg.sort-${sort}`).show()
    tickets_list()
    return false
  })

  frame.on('show', tickets_list)

  return frame
}
