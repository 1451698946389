var { client } = require('clearthru')
import { Frame } from '../framework.js'

import { clients } from './clients.js'
import { frmFactures } from './factures.js'
import { frmTickets } from './tickets.js'
import { frmRecurrences } from './recurrences.js'
import { frmListeEnvoi } from './listeenvois.js'
import { produits } from './produits.js'
import { commandes } from '../adminSupplier/commandes.js'

// import { frmBKTemplates } from './bookkeeping/templates.js'

export async function admin(api, on_logout) {

  $('body').addClass('has-navbar-fixed-top')

  const node = $('body')
  const template = 'main'
  const frame = new Frame({ node, template })

  var burger = frame.$('.burger')
  var nav = frame.$('#navMenu')
  burger.on('click', function(){
    burger.toggleClass('is-active')
    nav.toggleClass('is-active')
  })
  nav.on('click', 'li > a', function(){
    burger.removeClass('is-active')
    nav.removeClass('is-active')
  })

  frame.$('.navbar-menu a.logout').on('click', function () {
    localStorage.removeItem('credentials')
    client.unlink(api)
    frame.remove()
    on_logout()
  })

  const ctx = {
    node: frame.$('div.frames'),
    nav: frame.$('aside.menu ul.menu-list.general,#navMenu ul.menu-list.general'),
    menu: frame.$('aside.menu,#navMenu'),
  }
  // const bkctx = {
  //   node: frame.$('div.frames'),
  //   nav: frame.$('aside.menu ul.menu-list.bookkeeping,#navMenu ul.menu-list.bookkeeping'),
  //   menu: frame.$('aside.menu,#navMenu'),
  // }

  var { frames, bookkeeping } = await api.getOptions()

  var navs = []

  if(!frames || frames.includes('clients')) {
    navs.push(clients(api, ctx))
  }
  if(!frames || frames.includes('factures')) {
    navs.push(frmFactures(api, ctx))
  }
  if(!frames || frames.includes('tickets')) {
    navs.push(frmTickets(api, ctx))
  }
  if(!frames || frames.includes('recurrences')) {
    navs.push(frmRecurrences(api, ctx))
  }
  if(!frames || frames.includes('produits')) {
    navs.push(produits(await api.getProduitsApi(), ctx))
  }
  if(!frames || frames.includes('commandes')) {
    navs.push(commandes(await api.getCommandesApi(), ctx, 'admin'))
  }
  if(!frames || frames.includes('listeenvois')) {
    navs.push(frmListeEnvoi(api, ctx))
  }

  // if(bookkeeping) {
  //   navs.push(frmBKTemplates(api, bkctx))
  // } else {
  //   frame.$('aside.menu .bookkeeping').hide()
  // }
  frame.$('aside.menu .bookkeeping').hide()

  navs = await Promise.all(navs)
  navs[0].show()

  frame.$('nav a.username').html("Administrateur")

  frame.show()

}
