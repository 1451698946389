
import { Frame } from '../framework.js'

import { fieldErrMsg, debounce, exclusive, thisRemoteError, showModal, NavFrame, Modal } from '../common.js'

import { frmclientDetail } from './clientDetail.js'

export async function clients(api, ctx) {

  var clientsApi = await api.getClientsApi()
  var produitsApi = await api.getProduitsApi()

  const frameOpts = {
    ...ctx,
    template: 'clients',
    label: 'Clients',
  }  
  const frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-admin`)
  const form = frame.forms.newUser

  frame.tables.clients.onClick(async (entry) => {
    var editing = frame.tables.clients.$(`tr.frame[docid="${entry._id}"]`)
    if(editing.length > 0) {
      editing.find('a.cancel').click()
      return
    }
    var row = frame.tables.clients.insertFrame('editClients', entry._id)
    row.form.load(entry)
    row.show(400)
    row.form.$('a.cancel').on('click', async function () {
      await row.hide(400)
      row.remove()
    })
    row.form.$('a.edit').on('click', exclusive(async function () {
      var data = await row.form.pack()
      await clientsApi.update(data)
      .then(async (entry) => {
        frame.tables.clients.update({ entry })
        await row.hide(400)
        row.remove()
        frame.tables.clients.message(`Le client ${entry.email} a été mis à jour.`, entry._id)
      })
      .catch(thisRemoteError('FormError', (err) => {
        err.form.forEach((field) => {
          row.form.error(fieldErrMsg[field.kind] || field.kind, field.path)
        })
      }))
    }))
    row.form.$('a.passwordReset').on('click', async function () {
      await clientsApi.passwordReset(entry._id)
      .then(async (entry) => {
        frame.tables.clients.update({ entry })
        frame.tables.clients.message(`Le mot de passe pour le client ${entry.email} a été réinitialisé. Un courriel a été envoyé.`, entry._id)
      })
    })
    row.form.$('a.effacer').on('click', showModal({
      message: 'Êtes-vous sûr de vouloir effacer ce client?',
      btnOk: 'Effacer',
      onOk: async () => {
        await clientsApi.remove(entry._id)
        await row.hide(400)
        row.remove()
        frame.tables.clients.update({ remove: entry._id })
      },
    }))
    row.form.$('a.ajoutProduit').on('click', async function () {
      var modal = new Modal('ajoutProduit')

      modal.$('button.add').on('click', () => {
        var cur = row.form.$('input[field="client.produits"]').val()
        var produits = cur && cur.split(',') || []
        var modele = modal.tables.produits.$(`tr.is-selected`).get().map((n) => $(n).data('entry').modele)
        produits = produits.concat(modele)
        row.form.$('input[field="client.produits"]').val(produits.join(','))
        modal.close()
      })

      modal.tables.produits.onClick(async (entry) => {
        modal.tables.produits.$(`tr[docid="${entry._id}"]`).toggleClass('is-selected')
        if(modal.tables.produits.$(`tr.is-selected`).length) {
          modal.$('button.add').removeAttr('disabled', 'disabled')
        } else {
          modal.$('button.add').attr('disabled', 'disabled')
        }
      })
      async function produits_list() {
        var term = modal.$('input.term').val() || ''
        modal.tables.produits.update(await produitsApi.list(term, { 'modele': 1 }))
      }      
      modal.$('input.term').on('keyup', debounce(produits_list, 200))
      await produits_list()
      modal.show()
    })
    row.form.$('a.clientDetail').on('click', async function () {
      var opened = $('li', frame.subNav).filter((idx, e) => {
        return $(e).attr('label') == `${entry.client.companie}`
      })
      if(opened.length) {
        opened.find('a').click()
      } else {
        var modal = new Modal('unlockPrompt')
        var unlock = async () => {
          var password = modal.form.$('input[field="pass"]').val()
          try {
            var clientDetail = await clientsApi.clientDetail(entry._id, password)
            const clientDetailCtx = {
              node: ctx.node,
              menu: ctx.menu,
              nav: frame.subNav,
              label: `${entry.client.companie}`,
            }
            frmclientDetail(clientDetail, clientDetailCtx)
            modal.close()
          } catch (err) {
            console.log(err)
            modal.form.error("Le mot de passe est invalide.")
          }
        }
        modal.$('button.ok').on('click', unlock)
        modal.form.$('input[field="pass"]').on('keypress', function (e) {
          if(e.keyCode == 13)
          {
            unlock()
          }
        })
        modal.show()
        modal.$('input[field="pass"]').focus()
      }
    })
  })

  async function users_list() {
    var term = frame.$('input.term').val() || ''
    var sortcol = frame.tables.clients.$('th[sort!=""]')
    var sortfield = sortcol.attr('field')
    var sortorder = (sortcol.attr('sort') == "down") ? 1 : -1
    var sort = {}
    sort[sortfield] = sortorder
    frame.tables.clients.update(await clientsApi.list(term, sort))
  }

  form.$('a.cancel').on('click', exclusive(async function () {
    await form.hide(400)
    frame.$('a.newUser').show(400)
  }))
  form.$('a.create').on('click', exclusive(async function () {
    const data = await form.pack()

    await clientsApi.update(data)
    .then(async () => {
      form.clear()
      form.$('.focus').focus()
      form.message(`Le répondant ${data.email} a été créé.`)
      await users_list()
    })
    .catch(thisRemoteError('FormError', (err) => {
      err.form.forEach((field) => {
        form.error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
    }))
  }))

  frame.$('a.newUser').on('click', function onNewUser() {
    frame.$('a.newUser').hide(400)
    form.show(400)
    form.$('.focus').focus()
  })

  frame.$('input.term').on('keyup', debounce(users_list, 200))

  frame.tables.clients.$('th.sort a').on('click', function () {
    var col = $(this).parent()
    var field = col.attr('field')
    var sort = col.attr('sort')
    col.siblings('.sort').attr('sort', '').find('svg').hide()
    sort = (!sort || sort == 'up') ? 'down' : 'up'
    col.attr('sort', sort)
    col.find('svg').hide()
    col.find(`svg.sort-${sort}`).show()
    users_list()
    return false
  })

  await users_list()

  return frame
}
