
import { Frame } from '../framework.js'

import { fieldErrMsg, debounce, exclusive, thisRemoteError, showModal, NavFrame } from '../common.js'

export async function commandes(api, ctx, role) {

  const frameOpts = {
    ...ctx,
    template: 'commandes',
    label: 'Commandes',
  }  
  const frame = new NavFrame(frameOpts)
  const form = frame.forms.newCommande

  frame.$().addClass(`role-${role}`)

  frame.tables.commandes.onClick(async (entry) => {
    var editing = frame.tables.commandes.$(`tr.frame[docid="${entry._id}"]`)
    if(editing.length > 0) {
      await editing.data('Frame').hide(400)
      editing.data('Frame').remove()
      return
    }
    var row = frame.tables.commandes.insertFrame('editCommandes', entry._id)
    row.form.load(entry)
    row.form.$('a.cancel').on('click', async function () {
      await row.hide(400)
      row.remove()
    })
    row.form.$('a.edit').on('click', exclusive(async function () {
      var data = await row.form.pack()
      await api.update(data)
      .then(async (entry) => {
        frame.tables.commandes.update({ entry })
        await row.hide(400)
        row.remove()
        frame.tables.commandes.message(`La commande ${entry.noCommande} a été mis à jour.`, entry._id)
      })
      .catch(thisRemoteError('FormError', (err) => {
        err.form.forEach((field) => {
          row.form.error(fieldErrMsg[field.kind] || field.kind, field.path)
        })
      }))
      .catch((err) => {
        row.form.error(err.message)
      })
    }))
    row.form.$('a.effacer').on('click', showModal({
      message: 'Êtes-vous sûr de vouloir effacer cette commande?',
      btnOk: 'Effacer',
      onOk: async () => {
        await api.remove(entry._id)
        await row.hide(400)
        row.remove()
        frame.tables.commandes.update({ remove: entry._id })
      },
    }))
    row.show(400)
  })

  async function commandes_list() {
    var term = frame.$('input.term').val() || ''
    var statut = frame.$('select.statut').val() || ''
    var sortcol = frame.tables.commandes.$('th[sort!=""]')
    var sortfield = sortcol.attr('field')
    var sortorder = (sortcol.attr('sort') == "down") ? 1 : -1
    var sort = { [sortfield]: sortorder }
    frame.tables.commandes.update(await api.list(term, statut, sort))
  }

  frame.$('input.term').on('keyup', debounce(commandes_list, 200))
  frame.$('select.statut').on('change', commandes_list)

  frame.tables.commandes.$('th.sort a').on('click', function () {
    var col = $(this).parent()
    var field = col.attr('field')
    var sort = col.attr('sort')
    col.siblings('.sort').attr('sort', '').find('svg').hide()
    sort = (!sort || sort == 'up') ? 'down' : 'up'
    col.attr('sort', sort)
    col.find('svg').hide()
    col.find(`svg.sort-${sort}`).show()
    commandes_list()
    return false
  })

  frame.on('show', commandes_list)

  return frame
}
