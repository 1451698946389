
import { Frame } from '../framework.js'

import { fieldErrMsg, debounce, exclusive, thisRemoteError, Modal, showModal, NavFrame } from '../common.js'

export async function frmRecurrences(api, ctx) {

  var recurrences = await api.getRecurrencesApi()
  var factures = await api.getFacturesApi()

  const frameOpts = {
    ...ctx,
    template: 'recurrences',
    label: 'Récurrences',
  }  
  const frame = new NavFrame(frameOpts)

  frame.tables.recurrences.onClick(async (entry) => {
    var editing = frame.tables.recurrences.$(`tr.frame[docid="${entry._id}"]`)
    if(editing.length > 0) {
      editing.find('a.cancel').click()
      return
    }
    var recurrence = await recurrences.recurrence(entry._id)
    var row = frame.tables.recurrences.insertFrame('recurrence', entry._id)
    row.form.load(await recurrence.load())
    row.show(400)

    row.form.$('a.editFactureTemplate').on('click', async function () {
      var modal = new Modal('editFactureTemplate')
      modal.tables.factures.onClick(async (rowFacture) => {
        var data = await row.form.pack()
        data.template = rowFacture._id
        var entry = await recurrence.update(data)
        row.form.load(entry)
        frame.tables.recurrences.update({ entry })
        modal.close()
      })
      async function factures_list() {
        var term = modal.$('input.term').val() || ''
        modal.tables.factures.update(await factures.list(term, 'Facture', null, { 'number': -1 }))
      }      
      modal.$('input.term').on('keyup', debounce(factures_list, 200))
      await factures_list()
      modal.show()
    })

    row.form.$('a.cancel').on('click', async function () {
      await row.hide(400)
      row.remove()
    })
    row.form.$('a.edit').on('click', exclusive(async function () {
      var data = await row.form.pack()
      await recurrence.update(data)
      .then(async (entry) => {
        frame.tables.recurrences.update({ entry })
        await row.hide(400)
        row.remove()
        frame.tables.recurrences.message(`La récurrence ${entry.description} a été mis à jour.`, entry._id)
      })
      .catch(thisRemoteError('FormError', (err) => {
        err.form.forEach((field) => {
          row.form.error(fieldErrMsg[field.kind] || field.kind, field.path)
        })
      }))
    }))
    row.form.$('a.effacer').on('click', showModal({
      message: 'Êtes-vous sûr de vouloir effacer cette récurrence?',
      btnOk: 'Effacer',
      onOk: async () => {
        await recurrence.remove()
        await row.hide(400)
        row.remove()
        frame.tables.recurrences.update({ remove: entry._id })
      },
    }))

    row.form.$('input[type="file"]').on('change', function (e) {
      var f = e.target.files[0]
      if (!f.type.match('image.*')) {
        return
      }
      var reader = new FileReader();
      reader.onload = () => {
        row.form.$('img[field="image"]')[0].src = reader.result
      }
      reader.readAsDataURL(f)
    })

  })

  async function recurrences_list() {
    var term = frame.$('input.term').val() || ''
    var sortcol = frame.tables.recurrences.$('th[sort][sort!=""]')
    var sortfield = sortcol.attr('field')
    var sortorder = (sortcol.attr('sort') == "down") ? 1 : -1
    var sort = {}
    sort[sortfield] = sortorder
    frame.tables.recurrences.update(await recurrences.list(term, sort))
  }

  frame.$('a.newRecurrence').on('click', async function () {
    var entry = await (await recurrences.recurrence()).load()
    await recurrences_list()
    frame.tables.recurrences.$(`tr.entry[docid="${entry._id}"]`).click()
  })

  frame.$('input.term').on('keyup', debounce(recurrences_list, 200))

  frame.tables.recurrences.$('th.sort a').on('click', function () {
    var col = $(this).parent()
    var field = col.attr('field')
    var sort = col.attr('sort')
    col.siblings('.sort').attr('sort', '').find('svg').hide()
    sort = (!sort || sort == 'up') ? 'down' : 'up'
    col.attr('sort', sort)
    col.find('svg').hide()
    col.find(`svg.sort-${sort}`).show()
    recurrences_list()
    return false
  })

  frame.on('show', recurrences_list)

  return frame
}
