
import { fieldErrMsg, thisRemoteError, NavFrame, Modal, showModal } from '../common.js'

export async function frmListeEnvoi(api, ctx) {

  var listeEnvoisApi = await api.getListeEnvoisApi()

  var frameOpts = {
    ...ctx,
    template: 'listeenvois',
    label: "Liste d'envois",
  }
  var frame = new NavFrame(frameOpts)
  var form = frame.form

  frame.$("a.envoyer").on('click', showModal({
    message: 'Êtes-vous sûr de vouloir envoyer ce courriel?',
    btnOk: 'Envoyer',
    onOk: async () => {
      var data = await form.pack()
      data.etiquettes = form.$('div.etiquettes button.is-primary').map(function () {
      return $(this).attr('etiquette')
      }).get()
      await listeEnvoisApi.envoi(data)
      .then(() => {
        form.message("Message Envoyé.")
      })
      .catch((err) => {
        form.error(err)
        console.log(err)
      })
    },
  }))

  form.$().on('click', 'div.etiquettes button', function () {
    $(this).toggleClass('is-primary')
  })

  frame.on('show', async () => {
    var etiquettes = await listeEnvoisApi.getEtiquettes()
    form.$('div.etiquettes').empty()
    for(let [etiquette, number] of etiquettes) {
      form.$('div.etiquettes').append(`<button etiquette="${etiquette}" class="etiquette button is-rounded">
          <span class="icon is-small">
            <i class="fas fa-check"></i>
          </span>
          <span>${etiquette}: ${number}</span>
        </button>`)
    }
  })

  return frame
}
