var { client } = require('clearthru')
import { Frame } from '../framework.js'

import { clients } from './clients.js'
import { frmTickets } from './tickets.js'

export async function tech(api, on_logout) {

  $('body').addClass('has-navbar-fixed-top')

  const node = $('body')
  const template = 'main'
  const frame = new Frame({ node, template })

  var burger = frame.$('.burger')
  var nav = frame.$('#navMenu')
  burger.on('click', function(){
    burger.toggleClass('is-active')
    nav.toggleClass('is-active')
  })
  nav.on('click', 'li > a', function(){
    burger.removeClass('is-active')
    nav.removeClass('is-active')
  })

  frame.$('.navbar-menu a.logout').on('click', function () {
    localStorage.removeItem('credentials')
    client.unlink(api)
    frame.remove()
    on_logout()
  })

  const ctx = {
    node: frame.$('div.frames'),
    nav: frame.$('ul.menu-list.general'),
    menu: frame.$('aside.menu,#navMenu'),
  }

  ;(await clients(api, ctx)).show()
  await frmTickets(api, ctx)

  frame.$('aside.menu .bookkeeping').hide()

  frame.$('nav a.username').html("Technicien")

  frame.show()

}
