
import { fieldErrMsg, thisRemoteError, debounce, NavFrame, Modal, showModal } from '../common.js'

import { facture_load } from './factures.js'

export async function frmTicket(api, ticket, ctx) {

  var clients = await api.getClientsApi()
  var factures = await api.getFacturesApi()

  var frameOpts = {
    ...ctx,
    template: 'ticket',
    label: ctx.label,
    closable:true,
  }
  var frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-admin`)
  var form = frame.form

  function form_load(entry) {
    form.load(entry)
    frame.label(`${entry.type} ${entry.number}`)
    form.$('.actions a.state').hide()
    if(!entry.user) {
      return
    }
    // Nouveau, Encours, Complété, Approuvé, Débité, Facturé, Annulé
    if(entry.statut == 'Nouveau') {
      form.$('.actions a.annuler').show()
      form.$('.actions a.commencer').show()
    }
    if(entry.statut == 'En cours') {
      form.$('.actions a.annuler').show()
      form.$('.actions a.completer').show()
    }
    if(entry.statut == 'Complété') {
      form.$('.actions a.annuler').show()
      form.$('.actions a.approuver').show()
    }
    if(entry.statut == 'Approuvé') {
      form.$('.actions a.annuler').show()
      form.$('.actions a.debiter').show()
      form.$('.actions a.facturer').show()
    }
    if(entry.statut == 'Débité') {
      //
    }
    if(entry.statut == 'Facturé') {
      //
    }
    if(entry.statut == 'Annulé') {
      //
    }
  }


  frame.$('a.editClient').on('click', async function () {
    var modal = new Modal('editClient')
    modal.tables.clients.onClick(async (row) => {
      var entry = await ticket.update({
        'user': row._id,
      })
      form_load(entry)
      modal.close()
    })
    async function clients_list() {
      var term = modal.$('input.term').val() || ''
      modal.tables.clients.update(await clients.list(term, { 'client.companie': 1 }))
    }      
    modal.$('input.term').on('keyup', debounce(clients_list, 200))
    await clients_list()
    modal.show()
  })

  async function update(data) {
    await ticket.update(data)
    .then((entry) => form_load(entry))
    .catch(thisRemoteError('FormError', (err) => {
      err.form.forEach((field) => {
        form.error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
    }))
    .catch((err) => {
      console.log(err)
    })
  }

  form.onChange(update)

  function punch(heures) {
    form.$('table.role-admin[field="items"] tr:last input[field="quantite"]').val(heures)
    form.$('table.role-admin[field="items"] tr:last input[field="description"]').val('Temps travaillé').trigger('change').select()
  }
  form.$('a.uneheure').on('click', function () {
    punch(1)
  })
  form.$('a.trentemin').on('click', function () {
    punch(0.5)
  })
  form.$('a.quinzemin').on('click', function () {
    punch(0.25)
  })

  form.$('a.commencer').on('click', async function () {
    form_load(await ticket.commencer())
  })
  form.$('a.completer').on('click', async function () {
    form_load(await ticket.completer())
  })
  form.$('a.approuver').on('click', async function () {
    form_load(await ticket.approuver())
  })
  form.$('a.debiter').on('click', async function () {
    form_load(await ticket.debiter())
  })
  form.$('a.facturer').on('click', showModal({
    message: 'Êtes-vous sûr de vouloir facturer ce ticket?',
    btnOk: 'Facturer',
    onOk: async () => {
      var { ticket: entry, facture } = await ticket.facturer()
      form_load(entry)
      await facture_load(await factures.facture(facture._id))
    },
  }))
  form.$('a.annuler').on('click', showModal({
    message: 'Êtes-vous sûr de vouloir annuler ce ticket?',
    btnOk: 'Oui',
    btnCancel: 'Non',
    onOk: async () => {
      form_load(await ticket.annuler())
    },
  }))

  form.$('a.chatSend').on('click', async function () {
    var message = form.$('input.msg').val()
    form.$('input.msg').val('')
    var entry = await ticket.chat_send(message)
    form_load(entry)
  })

  var entry = await ticket.load()
  form_load(entry)

  frame.show()

  return frame
}
