

import { Frame } from '../framework.js'

import { fieldErrMsg, debounce, exclusive, thisRemoteError, NavFrame, showModal } from '../common.js'

import { frmFacture } from './facture.js'

var api
var ctx
var frame

export async function facture_load(facture) {
  var entry = await facture.load()
  const factureCtx = {
    node: ctx.node,
    menu: ctx.menu,
    nav: frame.subNav,
    label: `${entry.type} ${entry.number}`,
  }
  frmFacture(api, facture, factureCtx)
}

export async function frmFactures(_api, _ctx) {

  api = _api
  ctx = _ctx

  var factures = await api.getFacturesApi()

  var frameOpts = {
    ...ctx,
    template: 'factures',
    label: 'Factures',
  }  
  frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-admin`)

  frame.tables.factures.onClick(async (entry) => {
    var opened = $('li', frame.subNav).filter((idx, e) => {
      return $(e).attr('label') == `${entry.type} ${entry.number}`
    })
    if(opened.length) {
      opened.find('a').click()
    } else {
      await facture_load(await factures.facture(entry._id))
    }
  })

  frame.$('a.newSoumission').on('click', async function () {
    await facture_load(await factures.newFacture('Soumission'))
  })

  frame.$('a.newFacture').on('click', showModal({
    message: 'Êtes-vous sûr de vouloir créer une nouvelle facture?',
    btnOk: 'Créer',
    onOk: async () => {
      await facture_load(await factures.newFacture('Facture'))
    },
  }))

  async function factures_list() {
    var term = frame.$('input.term').val() || ''
    var type = frame.$('select.type').val() || ''
    var statut = frame.$('select.statut').val() || ''
    var sortcol = frame.tables.factures.$('th[sort][sort!=""]')
    var sortfield = sortcol.attr('field')
    var sortorder = (sortcol.attr('sort') == "down") ? 1 : -1
    var sort = { [sortfield]: sortorder }
    frame.tables.factures.update(await factures.list(term, type, statut, sort))
  }

  frame.$('input.term').on('keyup', debounce(factures_list, 200))
  frame.$('select.type').on('change', factures_list)
  frame.$('select.statut').on('change', factures_list)

  frame.tables.factures.$('th.sort a').on('click', function () {
    var col = $(this).parent()
    var field = col.attr('field')
    var sort = col.attr('sort')
    col.siblings('.sort').attr('sort', '').find('svg').hide()
    sort = (!sort || sort == 'up') ? 'down' : 'up'
    col.attr('sort', sort)
    col.find('svg').hide()
    col.find(`svg.sort-${sort}`).show()
    factures_list()
    return false
  })

  frame.on('show', factures_list)

  return frame
}
