
import { fieldErrMsg, thisRemoteError, debounce, NavFrame, Modal, showModal } from '../common.js'

export async function frmInfo(api, ctx) {

  var info = await api.getInfoApi()

  var frameOpts = {
    ...ctx,
    template: 'info',
    label: 'Informations',
  }
  var frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-client`)
  var form = frame.form
  form.readonly(true)

  async function refresh() {
    var entry = await info.load()
    form.load(entry)
  }

  form.$('a.refresh').on('click', refresh)
  refresh()

  return frame
}
