
import { fieldErrMsg, thisRemoteError, debounce, NavFrame, Modal, showModal } from '../common.js'

export async function frmclientDetail(client, ctx) {

  var frameOpts = {
    ...ctx,
    template: 'clientDetail',
    label: ctx.label,
    closable:true,
  }
  var frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-admin`)
  var formNotes = frame.forms.notes
  var formMemo = frame.forms.memo
  
  var uploadToken = await client.getUploadToken()

  frame.$('textarea[field="note"]').on('input', debounce(async function () {
    var data = await formNotes.pack()
    await client.update(data)
    .catch(thisRemoteError('FormError', (err) => {
      err.formNotes.forEach((field) => {
        formNotes.error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
    }))
    .catch((err) => {
      console.log(err)
    })
  }, 1000))

  frame.$('textarea[field="memo"]').on('input', debounce(async function () {
    var data = await formMemo.pack()
    await client.update(data)
    .catch(thisRemoteError('FormError', (err) => {
      err.formMemo.forEach((field) => {
        formMemo.error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
    }))
    .catch((err) => {
      console.log(err)
    })
  }, 1000))

  frame.tables.files.onClick(async (entry, action) => {
    if(action == 'delete') {
      showModal({
        message: 'Êtes-vous sûr de vouloir effacer ce fichier?',
        btnOk: 'Effacer',
        onOk: async () => {
          await client.deleteFile(entry.token)
          frame.tables.files.update(await client.listFiles())
        },
      })()
    } else {
      var downloadUrl = `/download?token=${entry.token}`
      window.open(downloadUrl, '_blank')
    }
  })

  frame.$('a.upload').on('click', async function () {
    frame.$('input.file').click()
  })
  frame.$('input.file').on('change', async function () {
    var fd = new FormData()
    var file = frame.$('input.file')[0].files[0]
    fd.append('file', file)

    $.ajax({
      url: `/upload?token=${uploadToken}`,
      type: 'POST',
      data: fd,
      contentType: false,
      processData: false,
      success: async function (response) {
        if (response != 0) {
          frame.tables.files.update(await client.listFiles())
        } else {
          alert('file not uploaded')
        }
      },
    })
  })

  var entry = await client.load()
  formNotes.load(entry)
  formMemo.load(entry)

  frame.tables.files.update(await client.listFiles())

  frame.show()

  return frame

}
