import { Frame } from './framework.js'

import { Modal, exclusive } from './common.js'

export async function validateEmail(api, params) {

  var token = params.get("token")

  var node = $('body')
  var template = 'validateEmail'
  var frame = new Frame({ node, template })

  frame.$('.save').on('click', async function () {
    var { pass1, pass2 } = await frame.form.pack()
    if(!pass1 || pass1.length < 8) {
      frame.form.error("Le mot de passe doit être d'une longueur minimale de 8 caractères.", "pass1")
      return
    }
    if(pass1 != pass2) {
      frame.form.error("Les mots de passes ne correspondent pas.", "pass2")
      return
    }
    await api.setPassword(token, pass1)
    frame.$('div.newpassword').hide()
    frame.$('div.passwordset').show()
  })

  function changePassword() {
    frame.$('div.newpassword').show()
    //frame.$('input.focus').focus()
  }

  frame.show()

  if(token) {
    try {
      await api.validateEmail(token)
      localStorage.removeItem('credentials')
      changePassword()
    } catch (err) {
      console.log(err)
      frame.$('div.alreadyvalidated').show()
    }
  } else {
    frame.$('div.alreadyvalidated').show()
  }

}

export async function showFacture(api, params) {

  var token = params.get("token")
  var print = params.get("print")

  var facture = await api.getFacture(token)
  var entry = await facture.load()

  $('html').addClass('scroll')

  var node = $('body')
  var template = 'facture'
  var frame = new Frame({ node, template })
  var form = frame.form

  frame.$().addClass(`role-client container`)
  form.readonly(true)

  form.$('a.print').on('click', async function () {
    window.print()
  })

  form.$('a.pay').on('click', async function () {

    var modal = new Modal('payerFacture')
    modal.$("button.ok").on('click', exclusive(async () => {
      modal.$().addClass('wait')
      var paiement = await modal.forms.paiement.pack()
      try {
        await facture.payer(paiement)
      } catch(err) {
        modal.form.error("Les informations de paiment sont invalide, veuillez vérifier vos informations et essayez de nouveau.")
        modal.$().removeClass('wait')
        return
      }
      await modal.close()
      new Modal('payerSucces').show()
      var entry = await facture.load()
      form.load(entry)
      actions(entry)
    }))

    modal.forms.info.load(entry)
    modal.show()

  })

  form.$('a.approve').on('click', async function () {

    try {
      await facture.approve()
    } catch(err) {
      console.log(err)
      return
    }
    new Modal('approveSucces').show()
    var entry = await facture.load()
    form.load(entry)
    actions(entry)

  })

  function actions(entry) {
    form.$('.clientactions a.state').hide()
    if(entry.type == 'Soumission') {
      if(entry.statut == 'Nouveau' || entry.statut == 'Envoyé') {
        form.$('.clientactions a.approve').show()
      }
    } else {
      if(entry.paiementcc && (entry.statut == 'Nouveau' || entry.statut == 'Envoyé')) {
        form.$('.clientactions a.pay').show()
      }
    }
  }

  form.load(entry)
  actions(entry)

  frame.show()

  if(print) {
    window.print()
  }

}