import { exclusive, thisRemoteError, Modal } from './common.js';

import { Frame } from './framework.js'


export function login(boot, on_login) {

  function forgotPassword() {
    $('body').removeClass('has-navbar-fixed-top')

    const node = $('body')
    const template = 'forgotPassword'
    const frame = new Frame({ node, template })

    async function forgotPassword() {
      const { email } = await frame.form.pack()

      await boot.forgotPassword(email)
      frame.$('div.forgotPassword').hide()
      frame.$('div.emailsent').show()

    }  

    frame.$('button.reset').on('click', () => {
      forgotPassword()
      .catch((err) => {
        console.log(err)
      })
    })

    frame.show()
    frame.$('input.focus').focus()

  }

  $('body').removeClass('has-navbar-fixed-top')

  const node = $('body')
  const template = 'login'
  const frame = new Frame({ node, template })

  function login_failed(msg) {
    frame.form.error(msg, 'password')
  }

  async function authenticate(email, password, code) {
    const api = await boot.login(email, password, code)
    if(frame.$('.saveCredentials').is(":checked")) {
      localStorage.setItem('credentials', JSON.stringify({ email, password, code }))
    }
    frame.remove()
    on_login(api)
  }  

  frame.$('button.login').on('click', exclusive(async () => {
    frame.$().addClass('wait')
    var { email, password } = await frame.form.pack()

    authenticate(email, password)
    .catch(thisRemoteError('TFARequested', () => {
      frame.$().removeClass('wait')
      var modal = new Modal('tfaPrompt')
      var validate = async () => {
        modal.close()
        var code = modal.form.$('input[field="code"]').val()
        authenticate(email, password, code)
        .catch(() => {
          login_failed("Le code est invalide, veuiller réessayer.")
        })
      }
      modal.$('button.ok').on('click', validate)
      modal.form.$('input[field="code"]').on('keypress', function (e) {
        if(e.keyCode == 13)
        {
          e.preventDefault()
          validate()
        }
      })
      modal.show()
      modal.$('input[field="code"]').focus()
    }))
    .catch((err) => {
      console.log(err)
      frame.$().removeClass('wait')
      login_failed('Le courriel et/ou le mot de passe est invalide')
    })
  }))

  frame.$('a.forgotPassword').on('click', () => {
    frame.remove()
    forgotPassword()
    return false
  })

  const credentials = JSON.parse(localStorage.getItem('credentials'))
  if(credentials) {
    authenticate(credentials.email, credentials.password, credentials.code)
    .catch(() => {
      localStorage.removeItem('credentials')
      frame.show()
    })
  } else {
    frame.show()
    frame.$('input.focus').focus()
  }

}
