
import { fieldErrMsg, thisRemoteError, debounce, NavFrame, Modal, showModal } from '../common.js'

export async function frmclientDetail(client, ctx) {

  var frameOpts = {
    ...ctx,
    template: 'clientDetail',
    label: ctx.label,
    closable:true,
  }
  var frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-tech`)
  var form = frame.form
  form.readonly(true)

  var entry = await client.load()
  form.load(entry)

  frame.show()

  return frame
}
