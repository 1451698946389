
import { Frame } from '../framework.js'

import { fieldErrMsg, debounce, exclusive, thisRemoteError, showModal, NavFrame } from '../common.js'

export async function produits(api, ctx) {

  const frameOpts = {
    ...ctx,
    template: 'produits',
    label: 'Produits',
  }  
  const frame = new NavFrame(frameOpts)
  const form = frame.forms.newProduit

  frame.tables.produits.onClick(async (entry) => {
    var editing = frame.tables.produits.$(`tr.frame[docid="${entry._id}"]`)
    if(editing.length > 0) {
      editing.find('a.cancel').click()
      return
    }
    var row = frame.tables.produits.insertFrame('editProduits', entry._id)
    row.form.load(entry)
    row.show(400)
    row.form.$('a.cancel').on('click', async function () {
      await row.hide(400)
      row.remove()
    })
    row.form.$('a.edit').on('click', exclusive(async function () {
      var data = await row.form.pack()
      await api.update(data)
      .then(async (entry) => {
        frame.tables.produits.update({ entry })
        await row.hide(400)
        row.remove()
        frame.tables.produits.message(`Le produit ${entry.modele} a été mis à jour.`, entry._id)
      })
      .catch(thisRemoteError('FormError', (err) => {
        err.form.forEach((field) => {
          row.form.error(fieldErrMsg[field.kind] || field.kind, field.path)
        })
      }))
    }))
    row.form.$('a.effacer').on('click', showModal({
      message: 'Êtes-vous sûr de vouloir effacer ce produit?',
      btnOk: 'Effacer',
      onOk: async () => {
        await api.remove(entry._id)
        await row.hide(400)
        row.remove()
        frame.tables.produits.update({ remove: entry._id })
      },
    }))

    row.form.$('input[type="file"]').on('change', function (e) {
      var f = e.target.files[0]
      if (!f.type.match('image.*')) {
        return
      }
      var reader = new FileReader();
      reader.onload = () => {
        row.form.$('img[field="image"]')[0].src = reader.result
      }
      reader.readAsDataURL(f)
    })

  })

  async function produits_list() {
    var term = frame.$('input.term').val() || ''
    var sortcol = frame.tables.produits.$('th[sort][sort!=""]')
    var sortfield = sortcol.attr('field')
    var sortorder = (sortcol.attr('sort') == "down") ? 1 : -1
    var sort = {}
    sort[sortfield] = sortorder
    frame.tables.produits.update(await api.list(term, sort))
  }

  frame.$('a.newProduit').on('click', async function () {
    var produit = await api.update()
    await produits_list()
    frame.tables.produits.$(`tr.entry[docid="${produit._id}"]`).click()
  })

  frame.$('input.term').on('keyup', debounce(produits_list, 200))

  frame.tables.produits.$('th.sort a').on('click', function () {
    var col = $(this).parent()
    var field = col.attr('field')
    var sort = col.attr('sort')
    col.siblings('.sort').attr('sort', '').find('svg').hide()
    sort = (!sort || sort == 'up') ? 'down' : 'up'
    col.attr('sort', sort)
    col.find('svg').hide()
    col.find(`svg.sort-${sort}`).show()
    produits_list()
    return false
  })

  await produits_list()

  return frame
}
