
import { fieldErrMsg, thisRemoteError, debounce, NavFrame, Modal, showModal } from '../common.js'

export async function frmTicket(api, ticket, ctx) {

  var frameOpts = {
    ...ctx,
    template: 'ticket',
    label: ctx.label,
    closable:true,
  }
  var frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-client`)
  var form = frame.form

  function form_load(entry) {
    form.load(entry)
    frame.label(`${entry.type} ${entry.number}`)
    form.$('.actions a.state').hide()
  }

  async function update(data) {
    await ticket.update(data)
    .then((entry) => form_load(entry))
    .catch(thisRemoteError('FormError', (err) => {
      err.form.forEach((field) => {
        form.error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
    }))
    .catch((err) => {
      console.log(err)
    })
  }

  form.onChange(update)

  form.$('a.chatSend').on('click', async function () {
    var message = form.$('input.msg').val()
    form.$('input.msg').val('')
    var entry = await ticket.chat_send(message)
    form_load(entry)
  })

  var entry = await ticket.load()
  form_load(entry)

  frame.show()

  return frame
}
