
import { fieldErrMsg, thisRemoteError, debounce, NavFrame, Modal, showModal } from '../common.js'

export async function frmFacture(api, facture, ctx) {

  var clients = await api.getClientsApi()

  var frameOpts = {
    ...ctx,
    template: 'facture',
    label: ctx.label,
    closable:true,
  }
  var frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-admin`)
  var form = frame.form

  function form_load(entry) {
    form.load(entry)
    frame.label(`${entry.type} ${entry.number}`)
    form.$('.actions a.state').hide()
    if(!entry.user) {
      return
    }
    form.$('.actions a.clone').show()
    if(entry.type == 'Soumission') {
      form.$('.actions a.convert').show()
      if(entry.statut == 'Nouveau') {
        form.$('.actions a.send').show()
      } else {
        form.$('.actions a.resend').show()
      }
    } else {
      if(entry.statut == 'Nouveau') {
        form.$('.actions a.send').show()
        form.$('.actions a.paid').show()
        form.$('.actions a.cancel').show()
      }
      if(entry.statut == 'Envoyé') {
        form.$('.actions a.paid').show()
        form.$('.actions a.cancel').show()
        form.$('.actions a.resend').show()
      }
      if(entry.statut == 'Payé') {
        form.$('.actions a.reset').show()
        form.$('.actions a.resend').show()
      }
      if(entry.statut == 'Annulé') {
        form.$('.actions a.reset').show()
      }
    }
  }

  frame.$('a.editClient').on('click', async function () {
    var modal = new Modal('editClient')
    modal.tables.clients.onClick(async (row) => {
      var entry = await facture.update({
        'user': row._id,
      })
      form_load(entry)
      modal.close()
    })
    async function clients_list() {
      var term = modal.$('input.term').val() || ''
      modal.tables.clients.update(await clients.list(term, { 'client.companie': 1 }))
    }      
    modal.$('input.term').on('keyup', debounce(clients_list, 200))
    await clients_list()
    modal.show()
  })

  async function update(data) {
    await facture.update(data)
    .then((entry) => form_load(entry))
    .catch(thisRemoteError('FormError', (err) => {
      err.form.forEach((field) => {
        form.error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
    }))
    .catch((err) => {
      console.log(err)
    })
  }

  form.onChange(update)

  form.$('a.convert').on('click', showModal({
    message: 'Êtes-vous sûr de vouloir convertir la soumission en facture?',
    btnOk: 'Convertir',
    onOk: async () => {
      var entry = await facture.convertToFacture()
      form_load(entry)
    },
  }))

  form.$('a.clone').on('click', showModal({
    message: 'Êtes-vous sûr de vouloir cloner ce document?',
    btnOk: 'Cloner',
    onOk: async () => {
      var entry = await facture.clone()
      form_load(entry)
    },
  }))

  form.$('a.send, a.reset').on('click', async function () {
    var entry = await facture.send()
    form_load(entry)
  })
  form.$('a.resend').on('click', async function () {
    await facture.resend()
    new Modal('resendSuccess').show()
  })
  form.$('a.paid').on('click', async function () {
    var entry = await facture.paid()
    form_load(entry)
  })
  form.$('a.cancel').on('click', async function () {
    var entry = await facture.cancel()
    form_load(entry)
  })
  form.$('a.getUrl').on('click', async function () {
    var url = await facture.getUrl()
    window.open(url, '_blank')
  })

  var entry = await facture.load()
  form_load(entry)

  frame.show()

  return frame
}
