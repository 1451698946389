
import { Frame } from '../framework.js'

import { fieldErrMsg, debounce, exclusive, thisRemoteError, Modal, NavFrame } from '../common.js'

export async function achats(api, ctx) {

  const frameOpts = {
    ...ctx,
    template: 'achats',
    label: 'Achats',
  }  
  const frame = new NavFrame(frameOpts)
  const form = frame.forms.newProduit

  var totalline = {
    modele:'',
    manufacturier:'',
    quantite:'',
    strPrix:'',
    htmlActions:'',
  }
  var panier = {
    items: {},
    soustotal: 0,
    fraisExpedition: 0,
    tps: 0,
    tvq: 0,
    total: 0,
  }

  frame.$('a.caisse').on('click', async () => {
    var invoice = $("<div />").append(frame.tables.panier.$().clone())
    invoice.find('th:last-child,td:last-child').remove()

    var commande = {
      soustotal: panier.soustotal.toFixed(2),
      fraisExpedition: panier.fraisExpedition.toFixed(2),
      tps: panier.tps.toFixed(2),
      tvq: panier.tvq.toFixed(2),
      total: panier.total.toFixed(2),
      items: Object.values(panier.items).map((e) => ({
        produit: e._id,
        modele: e.modele,
        manufacturier: e.manufacturier,
        prix: e.prix,
        quantite: e.quantite,
      })),
      expedition: infoClient.companie + '\n' + infoClient.repondant + '\n' + infoClient.adresse,
      adresse: infoClient.adresse,
      telephone: infoClient.telephone,
      html: invoice.html().replace(/\n/g, ''),
      strTotal: panier.total.toFixed(2) + ' $',
    }

    var modal = new Modal('achat')
    modal.$("button.ok").on('click', exclusive(async () => {
      modal.$().addClass('wait')
      var paiement = await modal.form.pack()
      try {
        await api.acheter(paiement, commande)
      } catch(err) {
        modal.form.error("Les informations de paiment sont invalide, veuillez vérifier vos informations et essayez de nouveau.")
        modal.$().removeClass('wait')
        return
      }
      await modal.close()
      new Modal('achatSucces').show()
      panier.items = {}
      update_panier()
    }))

    if(false) {
      commande = {
        ...commande,
        firstName: 'Joe', 
        lastName: 'Blo', 
        cardNumber: '4124939999999990', 
        expirationMonth: 12, 
        expirationYear: 20, 
        cvv: 123,
      }
    }

    modal.form.load(commande)
    modal.show()

  })

  function update_panier() {
    panier.soustotal = 0
    var body = Object.values(panier.items)
    for(var item of body) {
      var total = (+item.quantite * item.prix)
      panier.soustotal += total
      item.total = total
      item.strTotal = total.toFixed(2)
    }
    panier.fraisExpedition = infoClient.fraisExpedition
    panier.tps = (panier.fraisExpedition + panier.soustotal) * 0.05
    panier.tvq = (panier.fraisExpedition + panier.soustotal) * 0.09975
    panier.total = panier.fraisExpedition + panier.soustotal + panier.tps + panier.tvq

    if(body.length) {
      frame.$('a.caisse').removeAttr('disabled')
      body.push({
        'label': 'Sous-Total:',
        'strTotal': panier.soustotal.toFixed(2),
        ...totalline,
      })
      body.push({
        'label': 'Expédition:',
        'strTotal': panier.fraisExpedition.toFixed(2),
        ...totalline,
      })
      body.push({
        'label': 'TPS (5 %):',
        'strTotal': panier.tps.toFixed(2),
        ...totalline,
      })
      body.push({
        'label': 'TVQ (9,975 %):',
        'strTotal': panier.tvq.toFixed(2),
        ...totalline,
      })
      body.push({
        'label': 'Total:',
        'strTotal': panier.total.toFixed(2),
        ...totalline,
      })
    } else {
      frame.$('a.caisse').attr('disabled', 'disabled')
    }
    frame.tables.panier.update({body})

  }

  frame.tables.panier.onClick(async (entry, action) => {
    if(action == "remove") {
      delete panier.items[entry._id]
      update_panier()
    }
  })

  var htmlActions = `
    <a href="#" actionbtn="remove">
      <i class="fas fa-trash-alt"></i>
    </a>
  `

  frame.tables.produits.onClick(async (entry) => {
    var modal = new Modal('ajoutPanier')
    modal.$("button.ok").on('click', async () => {
      var data = await modal.form.pack()
      var item = panier.items[entry._id] || (panier.items[entry._id] = { 'quantite': 0, 'label': '', htmlActions, ...entry})
      item.quantite += +data.quantite || 0
      update_panier()
      modal.close()
    })
    modal.form.load({...entry, strPrix: entry.prix.toFixed(2)+' $'})
    modal.show()

  })

  frame.tables.produits.update(await api.produits_list())

  var infoClient = await api.infoClient()

  return frame
}
