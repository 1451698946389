
import { fieldErrMsg, thisRemoteError, debounce, NavFrame, Modal, showModal } from '../common.js'

export async function frmFacture(api, facture, ctx) {

  var frameOpts = {
    ...ctx,
    template: 'facture',
    label: ctx.label,
    closable:true,
  }
  var frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-client`)
  var form = frame.form
  form.readonly(true)

  var entry = await facture.load()

  form.$('a.print').on('click', async function () {
    var url = await facture.getPrintUrl()
    window.open(url, '_blank')
  })

  form.$('a.pay').on('click', async function () {

    var modal = new Modal('payerFacture')
    modal.$("button.ok").on('click', exclusive(async () => {
      modal.$().addClass('wait')
      var paiement = await modal.forms.paiement.pack()
      try {
        await facture.payer(paiement)
      } catch(err) {
        modal.form.error("Les informations de paiment sont invalide, veuillez vérifier vos informations et essayez de nouveau.")
        modal.$().removeClass('wait')
        return
      }
      await modal.close()
      new Modal('payerSucces').show()
      var entry = await facture.load()
      form.load(entry)
      actions(entry)
    }))

    modal.forms.info.load(entry)
    modal.show()

  })

  form.$('a.approve').on('click', async function () {

    try {
      await facture.approve()
    } catch(err) {
      console.log(err)
      return
    }
    new Modal('approveSucces').show()
    var entry = await facture.load()
    form.load(entry)
    actions(entry)

  })

  function actions(entry) {
    form.$('.clientactions a.state').hide()
    if(entry.type == 'Soumission') {
      if(entry.statut == 'Nouveau' || entry.statut == 'Envoyé') {
        form.$('.clientactions a.approve').show()
      }
    } else {
      if(entry.paiementcc && (entry.statut == 'Nouveau' || entry.statut == 'Envoyé')) {
        form.$('.clientactions a.pay').show()
      }
    }
  }

  form.load(entry)
  actions(entry)

  frame.show()

  return frame
}
