
import { Frame } from '../framework.js'

import { fieldErrMsg, debounce, exclusive, thisRemoteError, showModal, NavFrame, Modal } from '../common.js'

import { frmclientDetail } from './clientDetail.js'

export async function clients(api, ctx) {

  var clientsApi = await api.getClientsApi()

  const frameOpts = {
    ...ctx,
    template: 'clients',
    label: 'Clients',
  }  
  const frame = new NavFrame(frameOpts)
  frame.$().addClass(`role-tech`)

  frame.tables.clients.onClick(async (entry) => {
    var editing = frame.tables.clients.$(`tr.frame[docid="${entry._id}"]`)
    if(editing.length > 0) {
      editing.find('a.cancel').click()
      return
    }
    var row = frame.tables.clients.insertFrame('editClients', entry._id)
    row.form.load(entry)
    row.form.readonly(true)
    row.show(400)
    row.form.$('a.cancel').on('click', async function () {
      await row.hide(400)
      row.remove()
    })
    row.form.$('a.clientDetail').on('click', async function () {
      var opened = $('li', frame.subNav).filter((idx, e) => {
        return $(e).attr('label') == `${entry.client.companie}`
      })
      if(opened.length) {
        opened.find('a').click()
      } else {
        var modal = new Modal('unlockPrompt')
        var unlock = async () => {
          var password = modal.form.$('input[field="pass"]').val()
          try {
            var clientDetail = await clientsApi.clientDetail(entry._id, password)
            const clientDetailCtx = {
              node: ctx.node,
              menu: ctx.menu,
              nav: frame.subNav,
              label: `${entry.client.companie}`,
            }
            frmclientDetail(clientDetail, clientDetailCtx)
            modal.close()
          } catch (err) {
            console.log(err)
            modal.form.error("Le mot de passe est invalide.")
          }
        }
        modal.$('button.ok').on('click', unlock)
        modal.form.$('input[field="pass"]').on('keypress', function (e) {
          if(e.keyCode == 13)
          {
            unlock()
          }
        })
        modal.show()
        modal.$('input[field="pass"]').focus()
      }
    })
  })

  async function users_list() {
    var term = frame.$('input.term').val() || ''
    var sortcol = frame.tables.clients.$('th[sort!=""]')
    var sortfield = sortcol.attr('field')
    var sortorder = (sortcol.attr('sort') == "down") ? 1 : -1
    var sort = {}
    sort[sortfield] = sortorder
    frame.tables.clients.update(await clientsApi.list(term, sort))
  }

  frame.$('input.term').on('keyup', debounce(users_list, 200))

  frame.tables.clients.$('th.sort a').on('click', function () {
    var col = $(this).parent()
    var field = col.attr('field')
    var sort = col.attr('sort')
    col.siblings('.sort').attr('sort', '').find('svg').hide()
    sort = (!sort || sort == 'up') ? 'down' : 'up'
    col.attr('sort', sort)
    col.find('svg').hide()
    col.find(`svg.sort-${sort}`).show()
    users_list()
    return false
  })

  await users_list()

  return frame
}
