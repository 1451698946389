var cli = require('clearthru').client
import { Frame } from '../framework.js'

import { NavFrame } from '../common.js'

import { achats } from './achats.js'
import { frmFactures } from './factures.js'
import { frmTickets } from './tickets.js'
import { frmInfo } from './info.js'

export async function client(api, on_logout) {

  $('body').addClass('has-navbar-fixed-top')

  const node = $('body')
  const template = 'main'
  const frame = new Frame({ node, template })
  
  var burger = frame.$('.burger')
  var nav = frame.$('#navMenu')
  burger.on('click', function(){
    burger.toggleClass('is-active')
    nav.toggleClass('is-active')
  })
  nav.on('click', 'li > a', function(){
    burger.removeClass('is-active')
    nav.removeClass('is-active')
  })

  frame.$('.navbar-menu a.logout').on('click', function () {
    localStorage.removeItem('credentials')
    cli.unlink(api)
    frame.remove()
    on_logout()
  })

  const ctx = {
    node: frame.$('div.frames'),
    nav: frame.$('ul.menu-list.general'),
    menu: frame.$('aside.menu,#navMenu'),
  }

  ;(await achats(await api.getAchatsApi(), ctx)).show()
  await frmFactures(api, ctx)
  await frmTickets(api, ctx)
  await frmInfo(api, ctx)

  frame.$('nav a.username').html(await api.getUserName())

  frame.show()
}
